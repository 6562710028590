<template>
  <section id="published">
    <v-card class="custom-border border pa-2">
      <section class="d-flex flex-row align-center">
        <v-card-title>
          <h4 class="poppins secondary-1--text fw600">Published</h4>
        </v-card-title>
        <v-spacer />
        <div class="d-flex justify-end">
          <v-btn 
            text 
            small
            @click="gallery =  true"
            class="hidden-sm-and-down"
          >
            <v-icon color="" :class="gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-view-gallery-outline
            </v-icon>
          </v-btn>
          <v-btn 
            text 
            small
            @click="gallery = false"
            class="hidden-sm-and-down"
          >
            <v-icon color="" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </div>
      </section>

      <section class="mx-3">
        <SearchBar :placeholder="'Search course'" :outlined="true" :value.sync="search" @search="() => { page = 1, getPublishedCourses() }"/>
      </section>

      <v-progress-linear
          v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="4"
              class="my-3"
      ></v-progress-linear>
      
      <v-card-text v-if="courses.length>0" class="d-flex" :class="[$vuetify.breakpoint.mobile && 'justify-center', gallery ? 'flex-wrap' : 'flex-column']">
        <CourseCardGallery 
          v-if="gallery"
          v-for="(item,i) in courses"
          :key="item.id"
          :i="i" 
          :item="item" 
          :gallery="gallery"
          class="ma-3"
        />
        <CourseCardList 
          v-if="!gallery"
          v-for="(item,i) in courses"
          :key="item.id"
          :i="i" 
          :item="item" 
          class="ma-3"
        />
      </v-card-text>
      <v-sheet
        color="transparent"
        class="d-flex flex-column align-center justify-center pb-10"
        height="500"
        v-if="courses.length === 0"
      >
        <div class="">
          <v-img 
            max-width="250" 
            :src="require('@/assets/default/empty_published.png')" />
        </div>
        <h3 class="text-center poppins secondary-1--text fw600 my-5 text-center">
          You currently have no published courses.
        </h3>
        <div
          class="text-decoration-underline f14 poppins primary--text"
          @click="$emit('createCourse')"
        >
          Create Course
        </div>
      </v-sheet>
      <FormPagination 
        :pageCount="pageCount" 
        :page="page"
        :paginate="paginate"
        @page="(e) => {page = e, getPublishedCourses()}" 
        @paginate="(e) => {paginate = e, getPublishedCourses()}"/>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CourseCardGallery from "../../components/teacher/course/CourseCardGallery.vue";
import CourseCardList from "../../components/teacher/course/CourseCardList.vue";

export default {
  components: {
    CourseCardGallery,
    CourseCardList
  },
  data: () => ({
    loading: true,
    gallery: true,
    filter: null,
    pageCount: 1,
    paginate: '10',
    page: 1,
    search: ''
  }),
  computed: {
    ...mapState("instructor", {
      courses: (state) => state.courses,
    }),

  },
  mounted() {
    window.addEventListener('resize', this.onResize, {passive: true})
    this.onResize()
    this.getPublishedCourses()
  },
  methods: {
    ...mapActions("instructor", ["getPublishedCoursesAction"]),

    onResize(){
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        this.gallery = false
      }
    },

    getPublishedCourses(){
      this.loading = true
      this.getPublishedCoursesAction({ paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
        this.page = res.current_page
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>  